


@keyframes slideOut {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .slide-out {
    animation: slideOut 0.5s forwards;
  }


  .blink {
    animation: blink-animation 1s infinite;
  }
  
  @keyframes blink-animation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @font-face {
    font-family: 'MyTamilFontTopic';
    src: url('../../fonts/TAMUni-Tamil046.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'MyTamilFontLevel';
    src: url('../../fonts/TABUni-Tamil032.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'MyTamilFontEvent';
    src: url('../../fonts//TAMUni-Tamil195.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'MyTamilFontHeading';
    src: url('../../fonts//Tamilri_Chenetfont_5.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Pokemon';
    src: url('../../fonts//Pokemon\ Solid.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'tamilfont';
    src: url('../../fonts/NotoSansTamil-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }


  .editor-input{
    height: 100%;
    width: 100%;
  }

  